import React from 'react'
import Image from "gatsby-image";
import getIcon from '../constants/icons';
export default function Project({ data }) {
    const { title, color, stack, description, image, url } = data;
    return (
        <div className="card" style={{ '--color': color }}>
            <div className="text">
                <h1>{title}</h1>
                <p>{description}</p>
                <div className="tech-container">
                    {stack.map(({ icon, name, id }) => {
                        let Icon = getIcon(icon);
                        return <span key={id} className="tech-icon"><Icon /><span className="text">{name}</span></span>
                    })}
                </div>
                <a target="_blank" rel="noreferrer" className="external-link" href={url}>View Site </a>
            </div>
            <div className="image">
                <Image fluid={image.childImageSharp.fluid} />
            </div>
        </div>
    )
}
