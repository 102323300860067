import React from 'react'
import { Link } from 'gatsby'
import HashLink from './HashLink'
export default function Hero() {
    return (
        <React.Fragment>
            <div className="image-card">
                <div className="background"></div>
            </div>
            <div className="text-container">
                <div>
                    <h3 className="text-light">Hi I am</h3>
                    <h1>
                        <span className="text-white">Vanel Kamdoum</span>,
                            <span className="text-primary"> Software Developer</span>
                    </h1>
                    <div className="p-msg">
                        I love learning and problem-solving, I
                        value teamwork and good communication.
                        </div>
                </div>
                <div className="btn-group">
                    <HashLink to="#portfolio" className="btn btn-border">View Portfolio</HashLink>
                    <Link to="/contact" className="btn btn-full">Get in touch</Link>
                </div>
            </div>
            <div className="scroll">
                <div className="scroll-line">
                </div>
            </div>
        </React.Fragment>
    )
}
