import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import Projects from "../components/Portfolio";
import Blogs from '../components/Blogs';
export default ({data}) => {
  const {allStrapiProjects: { nodes: projects }, allStrapiBlogs:{ nodes: blogs } } = data;
  return (
    <Layout>
      <div className="section-container" id="home">
        <SEO title="Home"></SEO>
        <Hero></Hero>
      </div>
      <div className="section-container" id="portfolio">
        <Projects projects={projects} ></Projects>
      </div>
      <div className="section-container" id="blogs">
        <Blogs blogs={blogs} title="Latest Articles"showLink></Blogs>
      </div>
    </Layout>
  )
}

export const query = graphql`
{
  allStrapiProjects{
    nodes {
      title
      strapiId
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      url
      description
      color
      stack {
        icon
        name
        id
      }
    }
  }
  allStrapiBlogs(sort: {order: DESC, fields: date}, limit: 3) {
    nodes {
      strapiId
      title
      description
      category
      slug
      thumbnail {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      date(formatString: "MMMM Do, YYYY")
    }
  }
}
`