import React from 'react';
import Project from './Project';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

export default function Projects({ projects }) {

    return (
        <React.Fragment>
            <div className="content" >
                <div className="card-container">
                    {
                        projects.map(p => (
                            <Project key={p.strapiId} data={p} />
                        ))
                    }
                </div>
                <div className="controls-container">
                    <button className="cbtn control-left">
                        <AiOutlineLeft></AiOutlineLeft>
                    </button>
                    <button className="cbtn control-right">
                        <AiOutlineRight></AiOutlineRight>
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}
